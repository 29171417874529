

*, *:before, *:after, input[type="search"] { box-sizing: border-box }

.sidenav.dev{
  h1{
    background-color: red!important;
  }
}

.tapahtumat-selectors{

  select{
    margin-left:1em;
    margin-right:1em;
  }
}
h5.ennakko-header{
  cursor: pointer;
  &.red{
    color:red;
  }
}
.box-tapahtumat{
margin-top:80px!important;
}
.form-blocks-tapahtumat{
  margin-bottom:1.5em;

}
.no-results-row{
  text-align: center;
  font-size:.8em;
  p{
    margin-top:2em;
    margin-bottom:2em;
  }

}
.smaller-input input{
  max-width:100px;
}
.change-pwd{
  width:100%;
  h5{
    margin-bottom:0;
    font-size: .9em;
    font-weight: normal;
    font-size: 14.4px;
   
  }
  .form-blocks{
    padding-left:0;
    padding-right:0;
  }
}
@media(max-width:800px){
 
   body .App{

    .left{
      width:100%;
      min-height: 50px;
      height:50px;
      z-index:5;
      .sidenav{
        text-align: left;
       
       
       h1{
        margin-top:0!important;
        margin-bottom:0;
        
       }
      ul{
        display: none;
      }
    }
    }
    .right{
      width:100%;
      left:0;
      top:50px;
      .mycontent{
        .box{
          margin-top: 0px;
        }
      }
    }
    .topic-box{
      position: relative;
      top:0;
      width:100%;
    }
  
  }
}
.frontpage{

  .sidenav ul{
    display: flex;
    flex-wrap: wrap;
    list-style:  none;

    li{
      width:33.33%;
      margin-bottom:2em;
      &:last-child{
        display: none;
      }
a{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size:1.3em;
  
}
      svg{
        margin-bottom:1em;
        height: auto;
        width:20%;
        stroke:black!important;
        path{
          stroke:black!important;
        }
       
      }
    }
  }
}
.search-holder{

  position:relative;
  #reset{
    position: absolute;
    top:8px;
    right:10px;
    font-size:.7em;
    cursor: pointer;
    &:hover{
      font-weight: bold;
    }
  }
}
#search-results{
  position: absolute;
  background-color: white;
right:34px;
padding:1em;
top:58px;
filter: drop-shadow(0 0 0.1rem black);
  .search-item{
    width:190px;
    font-size:.9em;
    margin-top:.9em;
    margin-bottom:.9em;
    padding-bottom:.9em;
    border-bottom: 1px solid lightgray;
   
  }
  .topic_right{
    float:right;
    color:#5c6779;
    text-transform: uppercase;
  }
  .topic_left{
    float:left;
    color:#5c6779;
    text-transform: uppercase;
  }
  .title{
    font-size:1.1em;
  }
 
}
.inner{
 
}
.search-field{
  margin-left: auto;
  
  input{
    font-size:1.2em;
    min-width:150px;
    border:1px solid black;
    padding:.2em .7em;
    border:1px solid black;
    border-radius: 10px;
    outline:none;
  }
}
.filter-button{
  margin-left:1em;
}
.company-filters{
  padding-top:1em;
  div{
  padding-left:0;

  display: inline-block;
  margin-right:1em;
  span{
    cursor: pointer;
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
   
  }
  &.active span{
    color: lightgray;
  box-shadow: inset 0 0 10px #000000;
  }
}

 
}




.infos{
  padding-top:1em;
  padding-bottom:1em;
  .totalrows{
    margin-top:1em;
    p{
      margin-bottom:.2em;
      margin-top:0;
      strong{
        font-weight: normal;
      }
      &.result{
        font-weight: bold;
      }
    }
   
  }
  table{
    font-size:.8em;
    padding-top:1em;
    td,th{
      text-align: left;
    }
   
    tr td{
      padding-right:2em;
    }
    tr td:last-child{
      padding-right:0em;
     
      max-width:150px;
    }
  }
  
}
.add-new-person-link-holder{
  margin-right:.5em;
  a{
    cursor: pointer;
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
  }
}
span.tri{
  font-size:.5em;
  line-height: 20px;
  vertical-align: middle;
}
.topic-box{

  background-color: white;
  position: fixed;
  width:calc(100% - 230px);
  top:0;
  .inner{
    display: flex;
    align-items: center;
    margin-left:2em;
    margin-right:2em;
    
    h2{
      margin-right:.5em;
      font-size: 1.3em;
      font-weight: 400;
    }
  }
}

.mycontent .box{
  margin-top:60px;
  margin-left:2em;
  margin-right:2em;
}
.taxrates{
  thead th{
    text-align: left;
  }
}
h2 span.sulje{
 
  cursor: pointer;
  padding-left:1em;
  text-transform: uppercase;
  text-decoration: underline;
  &:hover{
    opacity:.5;
  }
}
.saldo{
  font-size:.9em;
  margin-top:0;
  margin-bottom:.5em;
}
.data-totals-pankki{
  font-weight: bold;
}
.pankki-row-hover{
  cursor: pointer;
}
.saldo.highlight{
color:red;
}
.hyvaksy{
cursor:pointer;
  &:hover{
    color:green;
  }
}
.smalltable{
  .btn-holder{
    display: flex;
    justify-content:end;
    margin-bottom:1em;
  }
  .btn{
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    display: inline-block;
  }
}
.reset-holder{
display: flex;
justify-content: right;
margin-bottom:.5em;
  span{
    cursor:pointer;
    text-transform: uppercase;
    font-size:.8em;
  }
}
.fixed-hide-non{

  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
 background-color:rgba(255,255,255,.75);
}
#fixed-holder{

  &.showfixed{
    input{
     width:200px;
    }
    td,th{
      padding:.4em;
      
    }
    td.td-btn{
      width:200px;
    }
    button{
      width:100%;

    }
    span.store-btn{
      margin-right:1em;
      cursor: pointer;
      background-color: #5c6779; 
      color:white;
      border-radius:15px;
      padding:.2em .75em;
      font-size:1em;
      font-weight: bold;
      text-transform: uppercase;
      &:hover{
        background-color: lightgray;
        color:black;
      }
    }
    .amount{
      margin-top:0;
      padding-top:0;
    }
    .medit{
      display: flex;
      input{
       width:100px;
      }
    }
    position:fixed;
    top:50%;
    width:500px;
    background-color: white;
    padding:1em;
    left:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 15px lightgray;
    
  }
}
.fixed-hide{

  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  /*background-color:rgba(255,0,0,.5);*/
}
.remove-me{
  cursor: pointer;
  &:hover{
    color:red;
  }
}
.jkcal-block{

  .selected-date{
    font-weight: bold;
    cursor: pointer;
  }
}
.jukkacalendar{
  position:absolute;
  width:calc(200px + 2em);
  background-color: white;
  box-shadow: 0px 0px 15px lightgray;
  .topic-bar{
    display: flex;
    align-items: center;
    .left,.right{
    
      width:60px;
      .calbtn{
        width:100%;
       
        text-align: center;
        cursor: pointer;
        background-color: #ececec; 
        color:#adadad;
        border-radius:15px;
        line-height: 2.2;
        font-size:.8em;
        &:hover{
          background-color: lightgray;
          color:#5c6779;
        }
      }
    } 
    h4{
      margin-top:0;
      margin-bottom:0;
      flex-grow: 1;
      font-size:1.4em;
      text-align: center;
    }
  }
  .holder{
    padding:1em;
  }
  .months{
    display: flex;
    flex-wrap: wrap;
    padding-top:1em;
     .month{
     
      box-sizing: border-box;
      cursor: pointer;
      &.disabled{
        color:lightgray!important;
        background-color: white!important;
      }
      &.selected{
        background-color:#3ead4b!important;
        color:white;
        font-weight: bold;
      }
      &:hover{
        background-color: lightgreen;
      }
      width:33.33%;
      padding-top:.75em;
      padding-bottom:.75em;
      
      display: flex;
      align-items: center;
      justify-content: center;
     }
  }
}
main.green{
  min-height: 100vh;
  background-color:#5dc560;
  p.forgot{
    width:100%;
    color:white;
  
   text-align: right;
   font-size:.8em;
   padding-right:0em;
   margin-top:.5em;
   
  
   span{
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      color:black;
     }
   }
   
  }
}
.loginContainer{

  min-height:100vh;

  display: flex;
  flex-direction: column;
  width:100%;

  align-items: center;
  justify-content: center;

  .loginForm{
   color:black;
    padding:1em;
    padding-bottom:.7em;
background-color: white;
    h2{
      font-size:1.1em;
      text-transform: uppercase;
    }
    .icon{
      margin-bottom:.5em;
      margin-top:.8em;
      text-align: center;
      svg{
        width:50px;
        height:auto;
      }
    }
    form{
      display: flex;
      flex-direction: column;
      align-items: center;
      
      label{
        text-transform: uppercase;
        display: flex;
        font-size:.9em;
        font-weight: 400;
        align-items: center;
        flex-direction: column;
        margin-bottom:.5em;
        input{
          display: block;
          min-width:200px;
          line-height:1.2;
          padding:.4em 0em .4em 0em;
          text-indent:.3em;
          border:none;
          outline:none;
          border-bottom:1px solid lightgray;

        }
      }
      .submitholder{
        width:100%;
        margin-top:1em;
      }
      input[type="submit"]{
        width:100%;
        text-transform: uppercase;
        color:white;
        background-color:#5dc560;
        border:0;
        outline:0;
        padding:.4em 0 .4em 0;
        line-height: 1.5;
      } 
    }
   
  }
}
td.gray{
  opacity:.4;

}



p.ennakko-info{
  font-size:.9em;
}
.print-laskelma{
  margin-left:2em;
  margin-right:2em;
  table{
    margin:0!important;
  }
}
.save-btn.disabled{

    opacity:.2;
}

.show-quitted,.memobutton,.memobutton2{
  padding-left:0;

  display: inline-block;
  margin-right:1em;
  span{
    cursor: pointer;
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
   
  }
  &.active span{
    color: lightgray;
  box-shadow: inset 0 0 10px #000000;
  }

 
}




@keyframes blinkki {
  0% { opacity: .5; }
  50% { opacity: 1; }
  100% { opacity: .5; }
}
.loader-animation-holder {

  display: inline-block;
  &.state-1{
    animation: blinkki 1s;
    animation-iteration-count: infinite;


  }
  &.state-2{
    
    color:green;
  }
  &.state-3{
    animation: blinkki 1s;
    animation-iteration-count: infinite;

    color:red;
  }
}
.side-scroll{

 
  overflow-x: scroll;
  table{
    white-space: nowrap;
  }
  .data-totals{
    font-weight: bold;
  }
}
.filters{
  position: absolute;
  background-color:white;
  top:60px;
  z-index:10;
  .inner-filters{

    margin:1em;
    label{
      font-size:.8em!important;
      text-transform: uppercase;
    }
    .company{

      &.active{
        font-weight: bold;
      }
    }

    .prefilters{
      .pre{
        cursor: pointer;
        text-transform: uppercase;
        font-size:0.9em;
        margin-bottom:.3em;
        margin-top:.3em;
      }
      .pre.active{
        font-weight: bold;
      }
    }
  }
}
.filter-button{
  .edit-btn{
    display: inline-block;
    margin-right:1em;
    span{
        cursor: pointer;
        padding:.5em 1em;
        background-color:#5c6779;
        font-size:0.7em;
        color:white;
        border-radius: 5px;
        text-transform: uppercase;
    }
    &.active span{
        color:lightgray;
      -moz-box-shadow:    inset 0 0 10px #000000;
      -webkit-box-shadow: inset 0 0 10px #000000;
      box-shadow:         inset 0 0 10px #000000;
    }
}

}
.tax-progress{
  .limit{

    font-size:.7em;
    .zero{
      border-left:1px solid black;
      padding-left:.5em;
    }
    .raja{
      float:right;
      border-right:1px solid black;
      padding-right:.5em;
    }
   
  }
  .track{
    width:100%;
    height:20px;
    position: relative;
    background-color: lightgray;
    border-right:1px solid black;
    border-left:1px solid black;
    .fill{
      background-color: black;
      position:relative;
      height:20px;
    }
  }
}
i.raja-info{
  font-size:0.8em;
  display: block;
}
#logoutli{
  margin-top:1em;
}
#logout{
  cursor: pointer;
  text-align: center;
  display: block;
  font-size:0.8em;

}
.print-page 
{
span{

  cursor: pointer;
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;


}}




@media print {
  table.table-tapahtumat{
 
    font-size:.8em;
    th{
    
      white-space: nowrap;
    }
  
}
.App .raporttilaskelmat{
  .side-scroll{
    overflow-x: visible;
  }
  table.table-reports{
    tbody{
     
    page-break-after: always;
    }
  

  }
  tr.text-header{
    th{
     max-width:200px;
   vertical-align: bottom;
    white-space: wrap;
    line-height: 1em!important;
   

    }
  }
}
  .App .no-print{
    display: none!important;
  }



  .App > .right .palkka-rows, .App > .right table.lisatiedot{
    td{
        white-space: nowrap!important;
    }
  }
  .App > .right table.palkkalaskelma.palkka-osoite .address_rows{

    min-width:400px;
   
    padding-top:85px!important;
    padding-bottom:75px!important;/**/
      span{
       
        font-size:1em!important;
        line-height: 1.4em;
        margin-left:60px!important;
      }
   
  }
  .App > .left {
   display: none!important;
  }
  .App > .right{
    tbody.palkka-rows, tbody.lisatiedot-rows,tr.verotus {
    
      td,th{
        font-size:.88em!important;
      }
     
    }
    position: static!important;
    left:0!important;
    right:0!important;
    .palkka-nav{
      display: none;
    }
    .buttons{
      display:none;
    }
  }

  
}

.settings-all{
.setting-section{
  h5{
    line-height: 1.5em;
    padding-left:.5em;
  }
}
  table{
    font-size:.9em;
    th,td{
      padding-right:.6em;
    }
  }
  .btn-remove{
    cursor: pointer;
    &:hover{
      color:red;
    }
  }
}

.results-preview{
  margin-bottom:2em;
  .wage-display-holder{
    padding:0;
    font-size:.9em;
    table{
      margin-left:0;
      margin-right:0;
    }
   
  }
}
.wage-display-holder{
.buttons{
  text-align: center;
  
  margin:2em;
  button{
   border:none;
   outline:none;
    cursor: pointer;
  
    padding: 1em 1em;
    background-color: #5c6779;
    font-size: .8em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    &:hover{
      background-color:black;

    }
  }
}
}
.provision-editor,.yrityslainat-editor{
  padding-top:1em;
  width:100%;
}
.smalltable{
  padding-top:.5em;
  padding-bottom:.5em;
table{
  font-size:.8em;
  width:100%;
  th{
    text-align: left;
   
  }
}

}
.palkka-nav{
  padding-left:0;
li{
  display: inline-block;
  margin-right:1em;
  span{
    cursor: pointer;
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
   
  }
  &.active span{
    color: lightgray;
  box-shadow: inset 0 0 10px #000000;
  }
}
 
}

.wage-display-holder{


  .yhteenveto-all{
    td{
      text-align: left;

    }
    th{
      padding-right:1em;
    }
  }
  max-width:900px;
  margin:0 auto;
  background-color:white; 
  padding:1em;
  
  table{
    margin:1em;
  }
}
.loma-row {
  flex-wrap: wrap;
}
.loma-row .col{
  display: block;

  width:25%;
  flex-basis: 25%;
  &.col-new-row{
    width:100%;
  flex-basis: 100%;
  font-size: .8em;
  padding-top: 1em;
  }
}
.error-wage{
  color:red;
  font-weight: bold;
}
.input-procent{
padding-right:0!important;

input{
  display:inline-block!important;
  width:calc(100% - 30px)!important;
}
  &:after{
    content:" %";
    display:inline;
  }
}
.palkat{
  padding-left:1em;
  padding-right:1em;
  font-size:.9em;
}
table.palkka-footer{

  tr.verotus{
    td,th{
      border-top:1px solid black;
      border-bottom:1px solid black;
      padding-top:1em;
      padding-bottom:1em;
      font-size:.9em;
    }
  }
  tr.address{
td{
  padding-top:1em;
  padding-bottom:1em;
  font-size:0.8em;
  text-align: center;
}
   

  }
}
table.lisatiedot{
  max-width:500px;
  &.fullrow{
    max-width:100%;
    margin-bottom:0;
  }
  margin-top:.2em;
  .header-row{
   th:last-child{
     text-align: right;
    }
  }
}
table.palkkalaskelma{
  border-collapse: collapse;
  font-size:.9em;
  width:100%;
 
 .header-row-palkkalajit th,.header-row-lisatietoja th{
   border-bottom:1px solid black;
   padding-top:.5em!important;
   padding-bottom:.5em!important;
  
   padding:0;
 }
 .header-row th{
   padding-top:1em;
 }
 tbody.palkka-rows,tbody.lisatiedot-rows{

  td{
    border-bottom:1px solid lightgray;
    padding-top:.5em!important;
    padding-bottom:.5em!important;
    padding-right:1em;
  }
  td.koodi{
    width:60px!important;
  }
  tr{
  
    td:last-child{
      text-align: right;
    }
  }
 }
  .border{
    border-top:1px solid black;
    padding-top:1em;
  }
  td.strong{
    font-weight: bold;
  }
  .address_rows{
    span{
      font-weight: bold;
      display: block;
    }
  }
  .info_rows{
    span{
   
      display: block;
    }
  }
  thead tr th{
  
  }
  tr th{
    text-align: left;
    
  }
  tr.header-row-lisatietoja th{
    padding-top:2em;
    border-bottom:1px solid black;
  }
}
body{
  background-color: #f3f5f7!important;
 min-height: 100%;
 height: 100%;
}
.sheet{
  table{
    width:100%;
    tr.item{
      td{
        font-size: .8em;
      }
      td.value{
        text-align: right;
      }
    }
    tr.total{

      td{
        text-align: right;
        font-weight:bold;
        font-size: .8em;
      }

    }
  }
}
.previouswages{

.paywage{
background-color: #f2f2f0;
margin-top: 0!important;
border-bottom:5px solid lightgray;
}

}
.preview .table{
  tbody tr td{

   
      padding-top:.3em;
   
  }
  tbody tr:last-child{
    font-weight: bold;

   
   td{
    padding-top:1em;
    &.amount{
      text-decoration: underline;
    }
   }
  }
}
.wage-content{
  .left{
    h4{
      margin:.6em 0em;
      user-select: none;
    }
    
  }
  .right{
    h3{
      font-size:1em;
      
    }
    h4{
      margin:.6em 0em;
      user-select: none;
    }
    .table{
      width:100%;
      font-size:.9em;
      .formula{
        font-size:.8em;
        display: none;
      }
      th{
        text-align: left;
      }
    }

  }
}
.memos{
  background-color:white;
}
.textarearow{
  margin-top:1em;
  margin-bottom:1em;
}
.textarea{
  label{
    display: block;
    font-size:1em;
    font-weight: bold;
    margin-bottom:.1em;
  }
  textarea{
    width:100%;
          
    border:1px solid #f3f5f7;
    background-color:#f3f5f7;
    font-size:1em;
    padding:.4em .4em;
    outline: none;
    min-height:50px;
  }
  textarea:focus{
    outline: none;
    border:1px solid black;
  }
}
.form-blocks{
  background-color: white;
  padding:1em;

  .form-block{

      .input{
        &.input-edit-false{
          margin-bottom:0;
          padding-right: 0em;
        }
        &.error{
          input{
            background-color:red;
            color:white;
          }
          .error-text{
            font-size:0.8em;
            color:red;
          }
        }
        label{
          font-size:.9em;
          padding-bottom:.2em;
        }
        input{
          
          border:1px solid #f3f5f7;
          background-color:#f3f5f7;
          font-size:1em;
          padding:.4em .4em;
          outline: none;
        }
        input:focus{
          outline: none;
          border:1px solid black;
        }
        
      }
  }
}

.medic{
  input:focus{
    outline: none;
    border:1px solid #3ead4b!important;
  }
  .leafnav{

    
    ul{
    border-bottom:3px solid#3ead4b;

    .active-li{
      background-color:#3ead4b;
      color:white;
      a{
        color:white;
      }
    }
    }
  }
}
.safe{
  input:focus{
    outline: none;
    border:1px solid red !important;
  }
 .leafnav{
  ul{
    border-bottom:3px solid red;
    .active-li{
      background-color: red;
      color:white;
      a{
        color:white;
      }
    }

    


  }
  }
}
.hidden{
  display: none;
}
.wage-content{
.both{
  display: flex;
  background-color: white;
 .left{
   flex-basis: 50%;
 }
 .right{
  flex-basis: 50%;
 }
}
}
.form-blocks{

  .row{
    display: flex;
    flex-basis:100%;
   
   .col{
    display: flex;
    flex-direction: column;
      justify-content: flex-end;
      

     .input{
    
      
      label{
       
      }
     
     }
   }
  }
  h4{display: block;width:100%;}
}

.overflownav .leafnav{
li.nav{
 &::-webkit-scrollbar{
  display: none;

 }
  overflow-x: scroll; 
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width:100%;
  ul{
    display: flex;
    
  }
}

}
.leafnav{
   &.overflownav{
  
   }
    ul{
      margin:0;
      padding-left:0;
      >li{
        user-select: none;
        display: inline-block;
        padding:.5em 1em;
        margin-right:.2em;
        opacity:.5;
        background-color:white;
        &.nav{
          padding:0;
          ul li{
            padding:.5em 1em!important;
           
          }

        }
        &.active-li{

          opacity:1;
          &.current-half{
            opacity:.5;
          }
        }
        a{
          line-height:1.2em;
         text-transform: uppercase;
         font-size:.9em;
         font-weight: bold;
        }
        &.nav{
          opacity: 1!important;
        }
        

    }
    border-bottom:3px solid  lightgray;

    li.nav{
      ul{
        border-bottom: none;
        li{
          padding:0;
          cursor:pointer;
        }
      }
        

    }


}

}

.mycontent{
  padding-left:0em;
  padding-right:0em;
  .table-new{
   
    border-collapse: collapse; 
    width:100%;
      tbody{
       
       
        overflow:auto;
        tr.data-row{
          border-top:1px solid lightgray;
     
          background-color:white;
       
          td{
font-size:.9em;
line-height: 3em;
          }

        }
        tr.text-header + tr{
          border-top:none;
        }
        tr td{
          padding:0em .4em;
        }

        &.medic{

          tr.text-header{
            border-bottom:3px solid#3ead4b;
          }
        }
        &.safe{

          tr.text-header{
            border-bottom:3px solid red;
          }
        }

        tr.text-header{
        
         
         
         
          th{
            padding:0em .4em;
            text-align: left;
            font-size:.9em;
            line-height: 2em;
            color:gray;
            text-transform: uppercase;
            user-select: none;
            &.orderable{
              cursor: pointer;

              &.order-active{
                color:black;
                &:after{
                  content:"▼";
                  padding-left:.5em;
                  font-size:.5em;
                  display: inline-block;
                  transform: translateY(-1px);
                }
                &.desc{
                  &:after{
                    content:"▲";
                    transform: translateY(-2px);
                  }
                }
              }
            }

          }
        }
        tr.header  {
     
          th{
          padding-top:3em;
          padding-bottom:1em;
          text-align: left;

          h2{
            margin-top:0;
            margin-bottom:0;
            span{
              font-size:.5em;
              font-weight: normal;
              text-transform: uppercase;
              display: block;
              color:gray;
            }

          }
          }
        }
      }

      }

}
.topnav{
  display: none!important;
}

.forms {
  
  fieldset {
    padding: 1em 1em;
    margin: 1em 0;
  }
  
  legend {
    padding: 0;
    font-weight: bold;
    font-size: 1.2em;
  }
  
  label {
    display: block;
    margin: .5em 0;
    font-size:.8em;
   
   text-transform: uppercase;
    
    b {
      //font-weight: normal;
    }
    
    // can I use :not here?
    textarea, select, input[type="text"], input[type="email"], input[type="search"], input[type="tel"], input[type="url"], input[type="password"], input[type="color"], input[type="file"], input[type="color"], input[type="number"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"] {
      display: block;
      width: 100%; // good?
    }
  }
  
  textarea, select, input[type="text"], input[type="email"], input[type="search"], input[type="tel"], input[type="url"], input[type="password"], input[type="color"], input[type="file"], input[type="color"], input[type="number"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"] {
      margin: .2em 0;
      padding: .25em .4em;
      border: 1px solid #ddd;
      max-width: 100%; // ????
  }
  
  input[type="search"] { 
    box-sizing: border-box;
  }
  
  input[type="file"] { 
    border-width: 0;
    padding: 0;
  }
  
  
  input[type="image"] {
    vertical-align: bottom;
  }
  
}















body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color:white;
  }
h4.arrow{
cursor:pointer;
  &:before{

    content:"▸";
    padding-right:.2em;
  }
  &.open:before{
    content:"▼";
    font-size:.5em;
  }
}
.row.closed{
  display: none!important;
}
  .period-topic{
    h2{
      display: inline-block;
    }
    &>div{
      
      display: inline-block;
      margin-left:1em;
      .period{
        font-weight: bold;
        cursor:pointer;
        &:after{
          content:"▼";
          color: gray;
          font-size:.6em;
          padding-left:.5em;
        }
      }
    }
  }
.right > .content{

  margin-top:0px;
}
  .topnav{

    position:fixed;
    width:100%;
    overflow:auto;
    background-color:#3b4557;
    min-height: 60px;
    display: flex;
   top:0;
    justify-content: flex-start;
    .search{

       flex-grow: 1;
       display: flex;
       justify-content: flex-end;
       input{
         display: none;
       }
    }
        ul{

            display: flex;
            list-style: none;
            padding-left:2em;
            align-items: center;
           h2{
               margin:0;
               font-size:1.1em;
               text-transform: uppercase;
           }
           li{
             margin-right:.5em;
             a{
               text-transform: uppercase;
               font-size:.8em;
               padding:.5em .7em;
               &.active{
                 background-color:lightblue;
                 border-radius:1em;
                 color:black;
               }
               cursor: pointer;
              color:white;
             }
           }
           
        }

  }
  .settings-all{
    width:100%;
  }
  .setting-section{
    flex-basis: 100%;
    width:100%;
    h5{
      width:100%;
      text-transform: uppercase;
      font-size:1em;
      padding:.2em;
      background-color:lightgray;
    }
  }
  .creditcard{
  .nettoform{
    background-color:whitesmoke;
    padding:1em;
display:flex;
    .col{
      flex-basis:20%;
      padding:.5em;
      &.store{
        display: flex;
       
        flex-shrink: 0;
        align-items: center;
        justify-content: right;
        button{
          margin-left:1em;
        }
      }
label{
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size:.9em;
  margin-bottom:.3em;

}
    }



  }

}
table.lainan-lyhennykset{
  display::block;
  width:100%;
}
  .paywage{
    &.content .inners{
      margin-left:1em;
      margin-right:1em;
      display:flex;
      flex-basis: 100%;
    }
   &.content .inner{
      width:50%;
      margin:0;


      &.leftinner{
       
        .box{
        border-right:1px solid lightgray;
        border-left:1px solid lightgray;
        }
      }

      &.rightinner{
        .box{
          border-left:none!important;

          border-right:1px solid lightgray;
        }

      }
      .box{
       
      
      }
    }
    h3{
        margin-top:2em;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
        margin-bottom:1em;
        flex-basis:100%;
        &.three{
          .col{
            flex-basis:33.33%;
          }
        }
        .col{
            flex-basis:50%;
            label{
                display: block;
            }
            &.col-wide{
                flex-basis:100%;
            }
        }
    }
  }
  .person-content{
      h2{
          display: inline-block;
          margin-right:1em;
          span{
              cursor: pointer;
              padding:.5em 1em;
              background-color:#5c6779;
              font-size:0.7em;
              color:white;
              border-radius: 5px;
              text-transform: uppercase;
          }
          &.active span{
              color:lightgray;
            -moz-box-shadow:    inset 0 0 10px #000000;
            -webkit-box-shadow: inset 0 0 10px #000000;
            box-shadow:         inset 0 0 10px #000000;
          }
      }
  }

  .edit-btn,.save-btn{
    display: inline-block;
    margin-right:1em;
    span{
        cursor: pointer;
        padding:.5em 1em;
        background-color:#5c6779;
        font-size:0.7em;
        color:white;
        border-radius: 5px;
        text-transform: uppercase;
    }
    &.active span{
        color:lightgray;
      -moz-box-shadow:    inset 0 0 10px #000000;
      -webkit-box-shadow: inset 0 0 10px #000000;
      box-shadow:         inset 0 0 10px #000000;
    }
}



  .App{

    min-height: 100%;
    >.left{

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width:230px;

        height:100%;
        border-left:1px;
        background-color:#1e1e1e;
      min-height: 100%;
      bottom:0;
      .sidenav{
        text-align: center;
      }
        h1{
            background-color:#5dc560;
            margin:0;
           
            padding:0;
            line-height:50px;
            width:50px;
            font-size:1.4em;
            margin:0 auto;
            border-radius:50%;
            display:inline-block;
            color:white;
            text-align:center;
            margin-top:1.5em;
            margin-bottom:1em;
        
        }
        ul{
            list-style: none;
            padding-left:0em;
            padding-right:0em;  
            margin-top:0;
            margin-left:1em;
            margin-right:1em;
            li{
            
            }
            li a{
             
                text-align: left;
               
             
                svg{
                    
                   
                    width:28px;
                  
                    height:auto;
                    margin:0;
                    padding-left:12px;
                    margin-right:.6em;
                    path{
                      stroke:#767a8b!important;
                        fill:#767a8b;
                       
                    }
                }
                font-size:1em;
                padding-top:.75em;
                padding-bottom:.75em;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;
                padding-left:0em;
                &.active{
                   
                    background-color:#282828;
                    border-radius:10px;
                        color:white;
                   
                    svg{
                     
                        path,stroke,fill{
                          
                          stroke: white !important;
                          fill: white!important;
                       
                            
                        }

                    }
                }
            }
            li,a{
                color:#767a8b;
                text-decoration: none;
            }
            
        }
       
    }
    >.right{
      
        position:absolute;
        left:230px;
        right:0px;
        
        
    }

  }
  
 
  .content
  {
      .inner{
        margin:1em;
        padding-top:1em;
        .box{
            padding:1em;
            background-color:#ffffff;
          border:1px solid lightgray;
         
            h2{
                font-size:1.3em;
                text-transform: uppercase;
                margin-top:0;
                &:after{
                    content: "";
                    display: table;
                    width:40px;
                    padding-top:5px;
                    border-bottom:2px solid #525a65;
                }
            }
        }
       .table{
           width:100%;
           border:none;
outline:none;
           th,td{
text-align:left;
padding:0;
margin:0;
border:none;
outline:none;

           }
           tbody{
            td{
              font-size:.8em;
              line-height:1.6em;
             }
             tr:nth-child(even){
  
              background-color:#f6f4f4;
             }
           }
           td,th{
             padding:.2em;
           }
           thead{
              tr.company-header{
                th{
                  font-size:1.2em;
                  line-height:2em;
                  padding-left:.5em;
                }
                background-color:#f0f0f0;
              }
              tr.field-header{
                th{
                  line-height:2em;
                }
              }
           }
           th{
               font-size:0.8em;
               text-transform: uppercase;
           }
          
          
       }
      }
    
  }


  a{
      color:black;
    text-decoration: none;
}

.form-block{
    display: flex;
    flex-wrap: wrap;
    margin-bottom:1.5em;
    h3{
        flex-basis:100%;
        font-weight: 400;
    }
    >.left{
        flex-basis:50%;
        
    }
    >.right{
        flex-basis:50%;
    }
    .input{
        padding-right:2em;
        margin-bottom:.5em;
        label{
            display: block;
            font-size:1em;
            font-weight: bold;
            font-weight: 300;
          
        }
        input{
            width:100%;
        }
    }
    .asetus{
      flex-basis: 100%;
      h5{
        text-transform: uppercase;
      }
    }
}

tr.gray{
  td{
    color:gray;
   
  }

}
.negative{


 



  td.amount{
    color:red;
    input{
      color:red;
    }
  }
}
.positive{

  td.amount{
    color:black;
    input{
      color:green;
    }
  }
}

tr.total-row 
{
  td{
 
  line-height: 3em;
  border-top:1px solid gray!important;
   font-weight: bold;
   
  }
 
  background-color: white!important;
}
.negative,.positive{

  
  td span,td div.input{
    display: inline-block;
    &.nega{
      padding-right:.2em;
    }


  }
}


.topicbar{

  display: flex;
  align-items: center;
  justify-content:flex-start; 
  .right-buttons{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

}
.sub-nav{
  span.active{
    font-weight: bold;
  }
}
.paywage{
  &.paywage-set{
    margin-top:4.5em;
  }
  h2{
    width:100%;
    margin-left:1em;
  }
  display: flex;
  flex-wrap: wrap;
  .left,.right{
   
    width:calc(50% - 4em);
    padding:2em;
  }
  .bottom{
    width:100%;
    display: flex;
    border-top:1px solid lightgray;
  }
  .buttons{
    width:100%;
    display: flex;
    border-top:1px solid lightgray;
    padding:2em;
    button{

      width:100%;
      padding-top:.5em;
      padding-bottom:.5em;
      cursor:pointer;
    }

  }
  .props{
    margin-bottom:0;
    .item-kaikki_yhteensa{
margin-top:2em;
    }
    .item-ilmoitettu_myynti,.item-provisio,.item-bonus,.item-kaikki_yhteensa,.item-kulukorvaukset_yhteensa,.item-brutto{
      span.value{
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .item{
      margin-bottom:.5em;
      display: flex;
      flex-wrap: wrap;
      span.label{
        font-weight: bold;
      
      }
      span.value{
        flex-grow: 1;
        text-align: right;
      }
      span.description{
        width:100%;
        font-style: italic;
        font-size:.9em;
      }
    }
  }
}








.month-picker {
    position: relative; }
    .month-picker > .rmp-container {
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      z-index: 100;
      top: 1px;
      left: -10000px;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container {
          position: fixed;
          top: 0;
          left: -10000px;
          width: 100%;
          height: 100%;
          transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
      .month-picker > .rmp-container.rmp-table {
        display: table; }
      .month-picker > .rmp-container.show {
        left: 0;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
        .month-picker > .rmp-container.show .rmp-overlay {
          left: 0; }
      .month-picker > .rmp-container .rmp-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 9;
        top: 0;
        left: -10000px;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        will-change: opacity;
        transform: translateZ(0);
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-overlay {
            background-color: rgba(0, 0, 0, 0.25); } }
      .month-picker > .rmp-container .rmp-cell {
        display: table-cell;
        vertical-align: middle;
        box-sizing: border-box;
        width: 100%;
        height: 100%; }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-cell {
            vertical-align: bottom; } }
      .month-picker > .rmp-container .rmp-popup {
        position: absolute;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
        margin: 0 auto;
        z-index: 10;
        font-size: 1.2rem;
        opacity: 0;
        border-radius: 3px;
        padding: 0.4rem;
        box-sizing: content-box; }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup {
            box-sizing: border-box;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            height: 14.4rem;
            transform: translate3d(0, 14.4rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range {
              height: 28rem;
              transform: translate3d(0, 28rem, 0); }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
                margin-top: 0.4rem; }
                .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                  margin-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup {
            transform: translate3d(0, -64px, 0);
            top: 0;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup.range {
              width: 40.6rem;
              padding: 0.6rem; } }
        .month-picker > .rmp-container .rmp-popup.show {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
        .month-picker > .rmp-container .rmp-popup:after {
          content: ' ';
          clear: both;
          display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad {
          position: relative; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad {
              box-sizing: border-box;
              float: left;
              width: 20rem; }
              .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
                float: right; } }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
            display: block;
            font-size: 1.4rem;
            text-align: center;
            line-height: 3.4rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
              font-weight: normal;
              margin-right: 0.5em; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
            font-style: normal;
            text-align: center;
            width: 3.4rem;
            height: 3.4rem;
            line-height: 3.4rem;
            position: absolute;
            top: 0; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
              left: 0; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
              right: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
            list-style-type: none;
            margin: 0;
            padding: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
            display: block;
            width: 100%; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
              content: ' ';
              clear: both;
              display: table; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li {
            display: block;
            float: left;
            text-align: center;
            font-size: 1.15rem;
            border-radius: 3px;
            line-height: 3.3rem;
            box-sizing: border-box;
            padding: 0.05rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            *white-space: nowrap;
            overflow: hidden;
            -webkit-font-smoothing: antialiased;
            -webkit-text-stroke-width: 0.2px;
            -moz-osx-font-smoothing: grayscale;
            transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
            @media screen and (max-width: 767px) {
              .month-picker > .rmp-container .rmp-popup .rmp-pad li {
                width: 25%; } }
            @media screen and (min-width: 768px) {
              .month-picker > .rmp-container .rmp-popup .rmp-pad li {
                width: 33.3333333333%; } }
            .month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
              background-clip: content-box; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
              border-radius: 1px; }
        .month-picker > .rmp-container .rmp-popup.light {
          color: #666;
          background-color: rgba(255, 255, 255, 0.96); }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup.light {
              border-top: 1px solid #ccc;
              box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
                border-top: 1px solid rgba(204, 204, 204, 0.5); }
                .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                  border-top: 0; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light {
              border: 1px solid #ccc;
              box-shadow: 0 1px 5px #ddd; }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
                background-color: rgba(238, 238, 238, 0.9); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
            cursor: pointer;
            moz-user-select: -moz-none;
            -moz-user-select: none;
            -o-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            @media screen and (min-width: 768px) {
              .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
                background-color: rgba(255, 227, 160, 0.59); } }
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
              background-color: #d3d3d3; }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
            background-color: rgba(31, 42, 58, 0.73);
            color: white; }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
            background-color: transparent;
            color: #bbb;
            cursor: default; }
        .month-picker > .rmp-container .rmp-popup.dark {
          color: #fff;
          background-color: rgba(50, 50, 50, 0.96); }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
              border-top: 1px solid rgba(113, 113, 113, 0.41); }
              .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
                border-top: 0; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
              background-color: rgba(70, 70, 70, 0.9); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
            cursor: pointer;
            moz-user-select: -moz-none;
            -moz-user-select: none;
            -o-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            @media screen and (min-width: 768px) {
              .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
                background-color: rgba(255, 210, 96, 0.33); } }
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
              background-color: #262828; }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
            background-color: rgba(189, 211, 242, 0.7);
            color: #303030; }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
            background-color: transparent;
            color: #717171;
            cursor: default; }
  
  .month-picker > .box {
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .month-picker > .box {
        box-sizing: border-box;
        line-height: 5rem;
        font-size: 1.32rem;
        color: #777777;
        text-align: right; } }
    @media screen and (min-width: 768px) {
      .month-picker > .box {
        border: 1px solid #e0e0e0;
        background-color: #f6f6f6;
        padding-left: 0.9rem;
        box-sizing: border-box;
        line-height: 3.2rem;
        font-size: 1.3rem;
        color: #525252; }
        .month-picker > .box > label {
          font-size: 1.3rem;
          color: #525252; } }
  
  .month-picker .tab.btn {
    font-size: 0; }
    .month-picker .tab.btn:before {
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-family: "icomoon";
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: 0.2px;
      font-size: 1.8rem;
      line-height: 3.4rem;
      text-align: center;
      width: 3.4rem; }
    .month-picker .tab.btn.prev:before {
      content: ""; }
    .month-picker .tab.btn.next:before {
      content: ""; }
  
  .years-panel {
    position: fixed;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    .years-panel.table {
      display: table; }
    .years-panel.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
    .years-panel.show > .overlay {
      left: 0;
      background-color: rgba(82, 82, 82, 0.49); }
    .years-panel > .overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: transparent;
      will-change: opacity;
      transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
    .years-panel .cell {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
    .years-panel .popup {
      display: inline-block;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.81);
      padding: 1.8rem; }
      .years-panel .popup textarea {
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1.5rem;
        width: 20rem;
        line-height: 150%;
        padding: 1rem; }
      .years-panel .popup:after {
        content: ' ';
        clear: both;
        display: table; }
    .years-panel.show .popup {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
  
  .list-area {
    margin-top: 5rem; }
    @media screen and (min-width: 768px) {
      .list-area {
        width: 500px;
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (max-width: 767px) {
      .list-area > ul {
        border-bottom: 1px solid #e8e8e8; }
        .list-area > ul > li {
          position: relative;
          background-color: #fff;
          border-top: 1px solid #e8e8e8;
          padding: 0 1.6rem; }
          .list-area > ul > li:after {
            content: ' ';
            clear: both;
            display: table; }
          .list-area > ul > li > label {
            float: left;
            display: block;
            line-height: 5rem;
            color: #440f24;
            font-size: 1.12rem; }
            .list-area > ul > li > label b {
              font-size: 1.1em; }
            .list-area > ul > li > label span {
              position: absolute;
              display: block;
              font-size: 0.8rem;
              color: #bfbfbf;
              left: 1.6rem;
              top: 1.5em; }
              .list-area > ul > li > label span i {
                display: inline-block;
                font-style: normal; }
                .list-area > ul > li > label span i.sub-btn {
                  display: none; }
          .list-area > ul > li .edit {
            margin-left: 9rem;
            line-height: 5rem; } }
    @media screen and (min-width: 768px) {
      .list-area > ul {
        padding: 1.8rem; }
        .list-area > ul > li {
          padding: 0.5rem 0 1.4rem;
          font-size: 1.3rem; }
          .list-area > ul > li:after {
            content: ' ';
            clear: both;
            display: table; }
          .list-area > ul > li > label {
            display: block;
            margin-right: 1rem;
            padding: 1px;
            color: #898989;
            font-size: 1.1rem;
            margin-bottom: 0.5em; }
            .list-area > ul > li > label b {
              display: block;
              font-size: 1.1em; }
            .list-area > ul > li > label span {
              display: block;
              font-size: 0.9em;
              color: #afafaf;
              margin-top: 0.4em; }
              .list-area > ul > li > label span i {
                display: inline-block;
                font-style: normal; }
                .list-area > ul > li > label span i.sub-btn {
                  padding: 0.1rem 0.3rem;
                  margin-left: 1.5rem;
                  border-radius: 3px;
                  background-color: #696969;
                  color: white;
                  font-size: 0.8em;
                  cursor: pointer; }
          .list-area > ul > li .edit {
            padding: 1px;
            height: 3.3rem; } }




